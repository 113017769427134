let ENDPOINTMKT = process.env.GATSBY_API_URL_MKT

export const amplifyconfig = {
  API: {
    endpoints: [
      {
        name: 'Picopulsemkt',
        endpoint: ENDPOINTMKT,
      },
    ],
  },
}
